// eslint-disable-next-line no-unused-vars
const env = process.env;

export const config = ({
  // Have to set default values for repo-generator
  scroll = () => {},
  IOS17Filled = () => {},
}) => {
  /**
   * Before changing anything check the repo generator index.js and vanilla.json
   *
   * Icons needs to be denoted as functions. For example:
   * icon: _ => Fluency({ name: 'money', size: '64' })
   * And when used in a component, they need to be called as functions: icon()
   */
  const configObj = {
    name: `IGA IT`,
    domain: `igait.com`,
    slogan: `Your Best Home Service`,
    tel: {
      tel1: `+447254766394`,
    },
    email: {
      email1: `ibispams101@gmail.com`,
    },
    mobileWidth: 767,
    chat: {
      showWidget: false,
    },
    socials: {
      instagram: `ev_constructing`,
    },
    nav: {
      dark: true,
      blur: `1px`,
      logoSrc: `/img/logo/logo.png`,
      logoStyle: {
        height: `40px`,
      },
      fadeOnPaths: ['/', '/contact'],
    },
    mainPics: {
      homePage: {
        imgPath: `/img/page/home.jpg`,
        logo: `/img/logo/logo.png`,
        height: `500px`,
        mobileHeight: `300px`,
        buttonText: `Browse`,
        onButtonClick: (navigate, input) => scroll(document.getElementById('services-section')?.offsetTop),
        inputPlaceHolder: `What are you looking for?`,
        inputOptions: [
          {
            label: `Kitchen`,
          },
          {
            label: `Bathroom`,
          },
          {
            label: `Roofing`,
          },
          {
            label: `Extensions`,
          },
          {
            label: `Flooring`,
          },
        ],
        formGroup: true,
        copy1: `Quality construction is the foundation of every great project. - John Smith`,
      },
      copy1: {
        imgPath: `/img/copy/1.jpg`,
        parallax: true,
        parallaxPace: 0.35,
        noShadow: true,
        imgBottom: `0%`,
        sloganCenter: true,
        slogan: `"That businesses with a strong online presence were more likely to see increased revenue and growth." - Harvard Business School`,
      },
      contactPage: {
        imgPath: `/img/page/contact.jpg`,
        height: `300px`,
        mobileHeight: `300px`,
      },
    },
    headers: {
      infos2: {
        part1: {
          text: `Our Most `,
        },
        part2: {
          text: `Popular Catergories`,
          gradient: true,
        },
      },
      infos3: {
        part1: {
          text: `Your `,
        },
        part2: {
          text: `Best Service`,
          gradient: true,
        },
      },
      infos4: {
        part1: {
          text: `Shed Light `,
          gradient: true,
        },
        part2: {
          text: `To Your Problems`,
        },
      },
      infos5: {
        part1: {
          text: `100% `,
          gradient: true,
        },
        part2: {
          text: `Satisfied Users`,
        },
      },
      album: {
        part1: {
          text: `Our Clients`,
        },
      },
      reviews: {
        part1: {
          text: `Reviews`,
        },
      },
      services: {
        part1: {
          text: `Services`,
        },
      },
    },
    textType: ['Kitchen', 'Bathroom', 'Roofing'],
    infos: {
      infos1: [
        {
          header: `Construction Services`,
          body: `Specializes in domestic and commercial construction services`,
          img: `/img/infos/infos1-1.jpg`,
          alt: `infos1-1`,
          icon: _ => IOS17Filled({ name: 'digger', size: '50', color: configObj.extras.iconColor1 }),
          onClick: (navigate, input) => scroll(document.getElementById('services-section')?.offsetTop),
        },
        {
          header: `Refurbishments`,
          body: `Offers refurbishment services for both residential and commercial properties`,
          img: `/img/infos/infos1-2.jpg`,
          alt: `infos1-2`,
          icon: _ => IOS17Filled({ name: 'smart-home-checked', size: '50', color: configObj.extras.iconColor1 }),
          onClick: (navigate, input) => scroll(document.getElementById('services-section')?.offsetTop),
        },
        {
          header: `Garden Work`,
          body: `Provides garden landscaping and maintenance services`,
          img: `/img/infos/infos1-3.jpg`,
          alt: `infos1-3`,
          icon: _ => IOS17Filled({ name: 'hand-planting', size: '50', color: configObj.extras.iconColor1 }),
          onClick: (navigate, input) => scroll(document.getElementById('services-section')?.offsetTop),
        },
      ],
      infos2: [
        {
          header: `Kitchen`,
          onClick: navigate => scroll(document.getElementById('services-section').offsetTop),
          icon: _ => IOS17Filled({ name: 'kitchen-room', size: '50', color: configObj.extras.iconColor2 }),
        },
        {
          header: `Bathroom`,
          onClick: navigate => scroll(document.getElementById('services-section').offsetTop),
          icon: _ => IOS17Filled({ name: 'shower-and-tub', size: '50', color: configObj.extras.iconColor2 }),
        },
        {
          header: `Roofing`,
          onClick: navigate => scroll(document.getElementById('services-section').offsetTop),
          icon: _ => IOS17Filled({ name: 'roofing', size: '50', color: configObj.extras.iconColor2 }),
        },
        {
          header: `Extensions`,
          onClick: navigate => scroll(document.getElementById('services-section').offsetTop),
          icon: _ => IOS17Filled({ name: 'neighborhood', size: '50', color: configObj.extras.iconColor2 }),
        },
        {
          header: `Flooring`,
          onClick: navigate => scroll(document.getElementById('services-section').offsetTop),
          icon: _ => IOS17Filled({ name: 'wooden-floor--v1', size: '50', color: configObj.extras.iconColor2 }),
        },
      ],
      infos3: {
        title: `What do we do?`,
        text: `IGA IT is a construction company that specializes in domestic and commercial construction services. They offer a wide range of services including refurbishments, garden work, kitchen and bathroom renovations, roofing, extensions, and flooring.`,
        buttonText: `Check Our Services`,
        img1: `/img/infos/infos2-1.jpg`,
        alt1: `infos2-1`,
        buttonOnClick: navigate => scroll(document.getElementById('services-section').offsetTop),
        flipperTitle: `ABC Construction Company`,
        flipperFlippedTitle: `ABC Construction Company`,
        flipperFlippedText: `IGA IT provided exceptional construction services for our office renovation. The team was professional and completed the project on time.`,
      },
      infos4: {
        header1: {
          part1: {
            text: `About Your `,
          },
          part2: {
            text: `Best Service`,
            gradient: true,
          },
        },
        header2: {
          part1: {
            text: `How does the process work?`,
          },
        },
        text1: `Clients will receive high-quality construction services`,
        text2: `Once a client decides to work with IGA IT, the process begins with a detailed consultation to understand the client's needs and preferences. Then, the team will proceed with the construction work, ensuring quality and timely completion.`,
        buttonText: `Explore Options`,
        buttonOnClick: navigate => scroll(document.getElementById('services-section')?.offsetTop),
        infos: [
          {
            header: `Quality`,
            body: `IGA IT ensures high-quality construction work for all projects`,
          },
          {
            header: `Timely Completion`,
            body: `Projects are completed within the agreed-upon timeframe`,
          },
          {
            header: `Professional Team`,
            body: `Experienced and skilled professionals handle all construction projects`,
          },
          {
            header: `Customization`,
            body: `Tailored construction solutions to meet individual client needs`,
          },
          {
            header: `Customer Satisfaction`,
            body: `Focus on ensuring customer satisfaction throughout the construction process`,
          },
          {
            header: `Reliability`,
            body: `Dependable construction services with over 10+ years of experience`,
          },
        ],
      },
      infos5: [
        {
          title: `Clients`,
          text: `+`,
          maxValue: 500,
          incrementBy: 500,
          updateInterval: 1,
          color: `rgb(112 144 183)`,
          strokeWidth: 1.5,
        },
        {
          title: `Projects`,
          text: `+`,
          maxValue: 150,
          incrementBy: 150,
          updateInterval: 1,
          color: `rgb(112 144 183)`,
          strokeWidth: 1.5,
        },
        {
          title: `Years in Service`,
          text: `+`,
          maxValue: 10,
          incrementBy: 10,
          updateInterval: 1,
          color: `rgb(112 144 183)`,
          strokeWidth: 1.5,
        },
      ],
      infos6: [
        {
          header: `Contact`,
          body: `Reach out to IGA IT for your construction needs`,
          onClick: navigate => scroll(document.getElementById('services-section')?.offsetTop),
        },
        {
          header: `Consultation`,
          body: `Schedule a consultation for your construction project`,
          onClick: navigate => scroll(document.getElementById('services-section')?.offsetTop),
        },
        {
          header: `Explore`,
          body: `Explore the various construction services offered by IGA IT`,
          onClick: navigate => scroll(document.getElementById('services-section')?.offsetTop),
        },
      ],
      contact: {
        header: `Contact Us`,
        header2: `Have a query for us?`,
        flipperHeader: `Contact Us`,
        flipperHeader2: `Different ways to reach us`,
        flipperImg: `/img/misc/contact.jpg`,
      },
    },
    cart: {
      cartName: `Cart`,
      cartSubmitButton: `Contact Us`,
      showPrice: true,
      showQuantity: true,
      showTotal: true,
      cartOnSubmit: async ({ e, inputs, cart, pushEmail }) => {
        e.preventDefault();

        try {
          await pushEmail({
            // Mail
            subject: 'New Order',
            to: configObj.email.email1,
            successReply: 'Order submitted to support',
            // HTML
            template: 'adminCart',
            args: { ...inputs, ...cart, currency: configObj.currency.currencySign },
          });

          await pushEmail({
            // Mail
            subject: 'Order submitted',
            to: inputs.email,
            successReply: 'Your order was submitted successfully',
            // HTML
            template: 'cart',
            args: { ...inputs, ...cart, currency: configObj.currency.currencySign },
          });
        } catch (e) {
          console.error(e);
        }
      },
    },
    reviews: [
      {
        title: `ABC Construction Company`,
        body: `IGA IT provided exceptional construction services for our office renovation. The team was professional and completed the project on time.`,
      },
      {
        title: `XYZ Builders`,
        body: `We hired IGA IT for our home extension project and were impressed with the quality of work. Highly recommend their services.`,
      },
      {
        title: `123 Renovations`,
        body: `Great experience working with IGA IT on our kitchen remodel. The team was efficient and the end result exceeded our expectations.`,
      },
    ],
    albums: {
      showAlbum: false,
      showInstagram: true,
      folder1: {
        md: 4,
        album: [
          '/img/album1/1.JPG',
          '/img/album1/2.JPG',
          '/img/album1/3.JPG',
          '/img/album1/4.JPG',
          '/img/album1/5.JPG',
          '/img/album1/6.JPG',
          '/img/album1/7.JPG',
          '/img/album1/8.JPG',
          '/img/album1/9.JPG',
          '/img/album1/10.JPG',
        ],
      },
    },
    services: {
      showPrice: true,
      showImage: false,
      multiclick: true,
      style: {},
      className: ``,
      serviceGroups: [
        {
          serviceGroupName: `Construction`,
          serviceItems: [
            {
              serviceName: `Kitchen`,
              id: `Kitchen`,
              price: 300,
              minPrice: true,
              imageSrc: ``,
              text: `Description for Kitchen.`,
            },
            {
              serviceName: `Bathroom`,
              id: `Bathroom`,
              price: 300,
              minPrice: true,
              imageSrc: ``,
              text: `Description for Bathroom.`,
            },
            {
              serviceName: `Roofing`,
              id: `Roofing`,
              price: 300,
              minPrice: true,
              imageSrc: ``,
              text: `Description for Roofing.`,
            },
            {
              serviceName: `Extensions`,
              id: `Extensions`,
              price: 300,
              minPrice: true,
              imageSrc: ``,
              text: `Description for Extensions.`,
            },
            {
              serviceName: `Flooring`,
              id: `Flooring`,
              price: 300,
              minPrice: true,
              imageSrc: ``,
              text: `Description for Flooring.`,
            },
          ],
        },
      ],
    },
    inputsOptions: {
      sortByOptions: [
        {
          value: ``,
          label: `Select`,
        },
        {
          value: `createdAt:asc`,
          label: `Oldest`,
        },
        {
          value: `createdAt:desc`,
          label: `Newest`,
        },
        {
          value: `updatedAt:asc`,
          label: `Updated - oldest`,
        },
        {
          value: `updatedAt:desc`,
          label: `Updated - soonest`,
        },
      ],
    },
    extras: {
      iconColor1: `d6ac83`,
      iconColor2: `8a6745`,
    },
  };

  return configObj;
};
